import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { SplCountdownService } from '@common/co/core/components/spl-countdown/spl-countdown.service';

@Component({
  selector: 'spl-athletes-app',
  templateUrl: './spl-athletes-app-layout.component.html',
  styleUrls: ['./spl-athletes-app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SplCountdownService]
})
export class SplAthletesAppLayoutComponent implements OnInit, OnDestroy {
  fuseConfig: any;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param splCountdownService
   */
  constructor(private _fuseConfigService: FuseConfigService,
              public splCountdownService: SplCountdownService) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
