import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

// import { } from '@fuse/'; //"rootDir": ".",

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import {
  TeamsClient,
  API_BASE_URL,
  OidcConfigurationClient,
} from '@common/services/co-api-client';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthModule } from '@common/co/auth/auth.module';
import { appConfig } from './core/config/app.config';
import { MaterialModule } from '@common/co/core/material.module';
import { SplCoreModule } from '@common/co/core/core.module';
import { InTakeModule } from '@common/co/feature/in-take/in-take.module';
import { NgxMaskModule } from 'ngx-mask';
import { SplHammerConfig } from 'app/core/config/hammer.config';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { DefaultPushNotificationResolver } from '@common/co/auth/resolvers/defaultPushNotification.resolver';
import { AppRoutes } from './app.routes';

import { BreadcrumbsService } from '@common/co/core/services/breadcrumbs.service';
import {
  APPLICATIONS_PATHS,
  COLORS,
  STRING_CONSTANTS,
} from '@common/co/core/app.constants';
import {
  colorConstants,
  ApplicationPaths,
  StringConstants,
} from 'app/core/app.constants';
import { APP_CONFIG } from '@common/co/core/config/app.config';
import {
  NAVIGATION,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { navigation, navigationConfig } from 'app/navigation/navigation';
import { APP_INFO } from '@common/co/core/config/app.info';
import { AppInfo } from 'app/core/config/app.info';
import { Error401Module } from '@common/shared/components/401/error-401.module';
import { CustomDialogModule } from '@common/shared/components/custom-dialog';
import { PreferencesModule } from '@common/co/feature/preferences/preferences.module';
import { ZendeskService } from '@common/co/core/services/zendesk.service';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from '@common/co/core/config/zendesk.config';
import { OverlayLoaderModule } from '@common/shared/components/overlay-loader';
import { SignalrService } from '@common/services/signalr.service';
import { ConfirmChangesModule } from '@common/shared/components/confirm-changes-dialog/confirm-changes.module';
import { APP_BUS_SERVICE } from '@common/shared/services/iapp-bus-service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { AUTH_SERVICE } from '@common/services/iauth-service';
import { OIDC_CONFIGURATION_CLIENT } from '@common/services/ioidc-configuration-client-service';
import { CoOidcConfigurationClientService } from '@common/services/co-oidc-configuration-client.service';
import { TECHNICAL_CLIENT } from '@common/shared/services/itechnical-client-service';
import { CoTechnicalClientService } from '@common/shared/services/co-technical-client.service';
import { APPLICATION_DICTIONARY_SERVICE } from '@common/services/iapplication-dictionary-service';
import { ApplicationDictionaryService } from 'app/core/services/constants.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@common/co/shared/shared.module';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutes,

    TranslateModule.forRoot({
      /*
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        */
      //missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: true,
    }),
    CustomDialogModule.forRoot({
      id: 'thera-custom-dialog',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'custom-dialog',
      panelClass: 'custom-dialog-panel',
    }),

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,

    // App modules
    SharedModule,
    LayoutModule,
    AppStoreModule,
    OverlayLoaderModule,
    InTakeModule,
    PreferencesModule,
    AuthModule,
    SplCoreModule.forRoot(DefaultPushNotificationResolver),
    MaterialModule,
    HammerModule,
    Error401Module,
    NgxMaskModule.forRoot(),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ConfirmChangesModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: API_BASE_URL, useFactory: getBaseUrl },
    { provide: COLORS, useValue: colorConstants },
    { provide: APPLICATIONS_PATHS, useValue: ApplicationPaths },
    { provide: APP_CONFIG, useValue: appConfig },
    { provide: NAVIGATION, useValue: navigation },
    { provide: NAVIGATION_CONFIG, useValue: navigationConfig },
    { provide: APP_BUS_SERVICE, useExisting: AppBusService },
    { provide: AUTH_SERVICE, useExisting: AuthorizeService },
    {
      provide: APPLICATION_DICTIONARY_SERVICE,
      useExisting: ApplicationDictionaryService,
    },
    {
      provide: OIDC_CONFIGURATION_CLIENT,
      useExisting: CoOidcConfigurationClientService,
    },
    {
      provide: TECHNICAL_CLIENT,
      useExisting: CoTechnicalClientService,
    },
    { provide: APP_INFO, useValue: AppInfo },
    { provide: STRING_CONSTANTS, useValue: StringConstants },
    TeamsClient,
    OidcConfigurationClient,
    { provide: HAMMER_GESTURE_CONFIG, useClass: SplHammerConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [AuthorizeService],
      multi: true,
    },
    BreadcrumbsService,
    SignalrService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSignalrService,
      deps: [SignalrService],
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(public zendeskService: ZendeskService) {}
}

export function getBaseUrl(): string {
  return appConfig.apiUrl;
}

export function initFunction(authServices: AuthorizeService) {
  return (): Promise<boolean> => authServices.initialize();
}

export function initSignalrService(signalrService: SignalrService) {
  return (): void => signalrService.initialize();
}
