import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { appGuards, MasterGuard } from '@common/co/auth/guards/master-guard';

const appRoutes: Routes = [
  {
    path: 'authentication/logout',
    redirectTo: 'authentication/login',
    pathMatch: 'full',
  },
  {
    path: 'error-401',
    loadChildren: () =>
      import('@common/shared/components/401/error-401.module').then(
        (m) => m.Error401Module,
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [
      MasterGuard
    ],
    data: {
      guards: appGuards,
      tokens: []
    }
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@common/co/feature/co-dashboard/co-dashboard.module').then(
        (m) => m.CoDashboardModule,
      ),
    canActivate: [
      MasterGuard
    ],
    data: {
      guards: appGuards,
    }
  },
  {
    path: 'assessments',
    loadChildren: () =>
      import(
        '@common/co/feature/testing-assessments/testing-assessments.module'
      ).then((m) => m.TestingAssessmentsModule),
    canActivate: [
      MasterGuard
    ],
    data: {
      guards: appGuards,
    }
  },
  {
    path: 'training',
    loadChildren: () =>
      import('@common/co/feature/training/training.module').then(
        (m) => m.TrainingModule,
      ),
    canActivate: [
      MasterGuard
    ],
    data: {
      guards: appGuards,
    }
  },
  {
    path: '**',
    redirectTo: commonNavigationConfig.dashboard.url,
    pathMatch: 'full',
  },
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  { useHash: false },
);
