<ng-container *ngIf="splCountdownService.countdown">
  <mat-toolbar fxLayout="row" fxLayoutAlign="center center" class="mobile-footer" *displayOnlyOnMobile>
    <countdown-panel ></countdown-panel>
  </mat-toolbar>
</ng-container>


<mat-toolbar fxLayout="row" fxLayoutAlign="center center" *displayOnlyOnDesktop>
  <span class="title">©{{ currentYear }} Sports Performance Lab</span>
</mat-toolbar>
